<template>
    <!-- 首页中action -->
    <div class="action_item" @click="jump(list.id)">   
        <div class="action_img">
            <img :src="list.thumb" alt="">
        </div>
        <div class="action_r">  
            <div class="title">
                {{list.title}}
            </div>
            <div class="time">
                {{list.publish_date}}
            </div>
            <router-link :to="`/action/${list.id}`" class="detail">查看详情 > ></router-link>
        </div>
    </div>
</template>

<script>
    export default {
        props:['list'],
        methods:{
            jump(id){
                this.$router.push('/action/' + id)
            }
        }
    }
</script>

<style lang='less' scoped>
.action_item{
    width: 394px;
    display: flex;
    cursor: pointer;
    .action_img{
        width: 120px;
        height: 100px;
        margin-right: 12px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .title{
        font-size: 16px;
        margin-bottom: 20px;
        color: #333333;
        width: 262px;
        height: 42px;
        line-height: 22px;
        margin-top: -4px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp:2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .time{
        font-size: 14px;
        color: #999999;
        margin-bottom: 5px;
    }
    .detail{
        font-size: 14px;
        color: #1D7FD7;
    }
}
</style>
<style scoped lang="less">
	*{
		margin: 0;
		padding: 0;
		border: 0;
		box-sizing: border-box;
	}
	// @keyframes line{
	// 	0%{
	// 		transform: translateX(0);
	// 	}
	// 	100%{
	// 		transform: translateX(-100%);
	// 	}
	// }	
	.main {
		width: 100%;
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		.contant{
			min-width: 1200px;
			margin-bottom: 30px;
			.notice_t{
				background: rgba(0,0,0,0.1);
				font-size: 14px;
				color: #383838;
				.notice_text{
					width: 1200px;
					margin: 0 auto;
					line-height: 40px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.notice_f{
					display:flex;
					align-items: center;
					.messageBox {
						width: 860px;
						// display: flex;
						// flex-wrap: nowrap;
						margin-left: 10px;
  						// overflow: hidden;
						// .info{
						// 	flex-shrink: 0;
						// 	animation: line 20s linear infinite;
						// }
					}
					span{
						font-size: 14px;
					}
				}
				.notice_r{
					display: flex;
					align-items: center;
					input{
						margin-left: 14px;
						width: 160px;
						height: 30px;
						padding-right: 30px;
						padding-left: 5px;
					}
					>div{
						position: relative;
					}
					.icon_search{
						cursor: pointer;
						width: 18px;
						height: 18px;
						background-image: url('../assets/image/homePage/icon-search.png');
						position: absolute;
						right: 10px;
						top: 10px;
					}
				}
			}
			.container{
				width: 1200px;
				// background: #F4F4F4;
				margin: 0 auto;
				.title{
					position: relative;
					margin-top: 11px;
					margin-left: 19px;
					font-size: 18px;
					color: #1D7FD7;
					display: flex;
					justify-content: space-between;
					align-items: center;
					a{
						width: 25px;
						height: 16px;
						margin-right: 12px;	
					}
					.title_xh{
						position: absolute;
						bottom: -8px;
						left: -9px;
						line-height: 5px;
					}
				}
				>div{
					margin-top: 14px;
					display: flex;
					// background: #F4F4F4;
					>div{
						background-color: #fff;
						// border-top: 2px solid #1D7FD7;
					}
				}
				.con_one{
					height: 398px;
					.lunbo{
						border-top: 0;
						width: 424px;
						margin-right: 12px;
						position: relative;
						>div{
							position: relative;
							height: 398px;
							display: none;
							cursor: pointer;
							img{
								width: 100%;
								height: 100%;
							}
						}
						>div.show{
							display: block;
						}
						.lunbo_text{
							width: 100%;
							position: absolute;
							bottom: 0;
							background: rgba(29,127,215,0.3);
							display: flex;
							align-items: center;
							justify-content: space-between;
							line-height: 40px;
							font-size: 16px;
							color: #fff;
							p{
								margin-left: 15px;
								width: 290px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
						ul{
							position: absolute;
							bottom: 14px;
							right: 14px;
							display: flex;
							align-items: center;
							>li{
								width: 6px;
								height: 6px;
								border-radius: 100%;
								background-color: #fff;
								margin-left: 10px;
								cursor: pointer;
							}
							li.active{
								width: 10px;
								height: 10px;
								border-radius: 100%;
								background-color: #FE6521;
							}
						}
					}
					.action{
						width: 424px;
						margin-right: 10px;
						background: rgba(65,149,158,0.1);
						border-top: 2px solid #41959E;
						.title{
							margin-bottom: 16px;
						}
						.action_item{
							margin-left: 14px;
							margin-bottom: 12px;
						}
					}
					.login{
						width: 330px;
						background: rgba(117,116,209,0.1);
						border-top: 2px solid #7574D1;
						.login_con{
							padding: 0 20px;
							/deep/.el-form{
								margin-top: 20px;
							}
							/deep/.el-form-item{
								margin-bottom: 26px;
							}
							/deep/.el-form-item:last-child,/deep/.el-form-item:nth-child(4){
								margin-bottom: 0;
							}
							/deep/.el-input__inner{
								position: relative;
								border: 0;
								// background-color: #F4F4F4;
								// width: 290px;
								height: 46px;
								border-radius: 4px;
								padding-left: 43px;
							}
							/deep/.el-button{
								width: 290px;
								height: 44px;
								line-height: 44px;
								color: #fff;
								font-size: 16px;
								background-color: #1D7FD7;
								border-radius: 4px;
							}
							/deep/.el-form-item__error{
								font-size: 14px;
								color: #FF1821;
							}
							.code{
								/deep/.el-form-item__content{
									display: flex;
									position: relative;
								}
								.yanzhen{
									width: 186px;
									margin-right: 8px;
								}
							}
							i{
								position: absolute;
								top: 16px;
								left: 14px;
								width: 16px;
								height: 16px;
								background-repeat: no-repeat;
							}
							.name_png{
								background-image: url('../assets/image/homePage/email.png');
							}
							.pass_png{
								background-image: url('../assets/image/homePage/pass.png');
							}
							.num_png{
								background-image: url('../assets/image/homePage/yanzheng.png');
							}
							.log{
								margin-top: 6px;
							}
							.gray{
								background-color: #CCCCCC;
							}
							.free{
								background-color: #FE6521;
								margin-top: 12px;
							}
							#canvas{
								height: 44px;
								border-radius: 4px;
								border: 1px solid #C3D4E3;
								cursor: pointer;
							}
							.forget{
								position: absolute;
								right: -6px;
								bottom: -20px;
								line-height: 14px;
								font-size: 14px;
								color: #FF1821;
								cursor: pointer;
							}
						}
					}
				}
				.con_two{
					min-height: 360px;
					>div{
						width: 593px;
					}
					.notice{
						margin-right: 14px;
						background: rgba(111,49,160,0.06);
						border-top: 2px solid #6F31A0;
					}
					.progress{
						background: rgba(0,175,80,0.06);
						border-top: 2px solid #00AF50;
					}
					.notice_cont{
						margin-top: 16px;
						padding: 0 10px;
						.notice_first{
							display: flex;
							margin-bottom: 21px;
							cursor: pointer;
							.notice_img{
								width: 190px;
								height: 134px;
								margin-right: 14px;
								img{
									width: 100%;
									height: 100%;
								}
							}
							.notice_content{
								flex: 1;
								.title{
									color: #FF5B06;
									font-size: 16px;
									height: 41px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp:2;
									overflow: hidden;
									text-overflow: ellipsis;
									// margin-top: 15px;
									margin: 0;
									margin-bottom: 10px;
								}
								.abstract{
									color: #6B6B6B;
									font-size: 14px;
									height: 56px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp:3;
									overflow: hidden;
									text-overflow: ellipsis;
									margin-bottom: 10px;
								}
								.more{
									font-size: 14px;
									color: #1D7FD7;
									display: flex;
									justify-content: space-between;
									.more_time{
										color: #999999;
										font-size: 14px;
									}
								}
							}
						}
						.list_item{
							margin-bottom: 18px;
						}
					}
				}
				.con_three{
					// background-color: #41959E;
					height: 290px;
					.people{
						width: 100%;
						background: rgba(65,149,158,0.1);
						border-top: 2px solid #41959E;
						.peo_lubo{
							margin-top: 24px;
							width: 1080px;
							height: 200px;
							margin-left: 60px;
							position: relative;
							.peo_img{
								overflow: hidden;
								.peo_view{
									display: flex;
									>div{
										width: 160px;
										height: 200px;
										flex-shrink: 0;
										position: relative;
										margin-right: 24px;
										img{
											width: 100%;
											height: 100%;
										}
									}
								}
								.peo_name{
									position: absolute;
									bottom: 0;
									width: 160px;
									height: 40px;
									color: #ffffff;
									display: flex;
									align-items: center;
									justify-content: center;
									background: rgba(65,149,158,0.9);
								}
							}
							.row_l,.row_r{
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								cursor: pointer;
							}
							.row_l{
								left: -44px;
							}
							.row_r{
								right: -44px;
							}
						}
					}
				}
				.con_four{
					height: 170px;
					.link{
						width: 860px;
						margin-right: 10px;
						overflow: hidden;
						background: rgba(117,116,209,0.1);
						border-top: 2px solid #7473D1;
						.link_img{
							margin-top: 22px;
							padding: 0 20px 22px 0;
							display: flex;
							// justify-content: space-around;
							a{
								display: inline-block;
								color: #333333;
								&:hover{
									color: #FF1821;
								}
								width: 140px;
								height: 90px;
								img{
									width: 100%;
									height: 100%;
								}
							}
							>div{
								width: 142px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
								margin-right: 6px;
								>div{
									position: absolute;
									left: -4px;
									top: 50%;
									margin-top: -16px;
									width: 2px;
									height: 32px;
									background-color: #7473D1;
								}
								&:first-child{
									>div{
										width: 0;
										height: 0;
									}
								}
							}
						}
					}
					.contact{
						width: 330px;
						color: #fff;
						background-image: url('../assets/image/homePage/contact.png');
						border-top: 2px solid #1D7FD7;
						.title{
							color: #FFFFFF;
						}
						.con_text{
							margin-top: 18px;
							margin-left: 20px;
							font-size: 14px;
							div{
								margin-bottom: 8px;
							}
							span{
								margin-left: 26px;
							}
						}
					}
				}
			}

		}
	}
	.text{
		font-size: 14px;
		color: red;
		// line-height: 14px;
	}
</style>
<template>
	<div class="main">
		<topHeader ind="0"></topHeader>
		<div class="contant">
			<div class="notice_t">
				<div class="notice_text">
					<div class="notice_f">
						<img src="../assets/image/homePage/notice.png" alt="">
						<div class="messageBox">
							<marqueeLeft :sendVal="newList" />
						</div>
					</div>
					<div class="notice_r">
						<span>站内搜索:</span>
						<div>
							<i class="icon_search" @click="search"></i>
							<input v-model="searchText" @keydown.13="search" type="text">
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="con_one">
					<div class="lunbo">
						<div @click="toDetails(lunboItem.id)" class="show">
						<!-- <div @click="toDetails(list.id)" v-for="(list,index) in lunboList" :key="list.id" :class="index == 0 ? 'show' : ''"> -->
							<img :src="lunboItem.thumb" alt="">
							<div class="lunbo_text">
								<p>{{lunboItem.title}}</p>
							</div>
						</div>
						<ul>
							<li :class="i == lunboActive ? 'active' : ''" v-for="(item,i) in lunboList" :key="i" @click="lunBoClick(item,i)" @mouseover="lunBoClick(item,i)"></li>
						</ul>
					</div>
					<div class="action">
						<div class="title">
							<span>学会动态</span>
							<router-link to="/action"><img src="../assets/image/homePage/more.png" alt=""></router-link>
							<div class="title_xh">
								<img src="../assets/image/homePage/title_bot.png" alt="">
							</div>
						</div>
						<ActionItem v-for="list in actionList" :key="list.id" :list='list' />
					</div>
					<div class="login">
						<div class="title">
							<span>会员登录</span>
							<div class="title_xh">
								<img src="../assets/image/homePage/title_bot.png" alt="">
							</div>
						</div>
						<div class="login_con">
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
  								<el-form-item prop="username">
    								<el-input type="text" v-model="ruleForm.username" placeholder='邮箱' autocomplete="off">
										<i slot="prefix" class="name_png"></i>
									</el-input>
  								</el-form-item>
  								<el-form-item prop="password">
    								<el-input type="password" v-model="ruleForm.password" placeholder='密码' autocomplete="off">
										<i slot="prefix" class="pass_png"></i>
									</el-input>
  								</el-form-item>
  								<el-form-item  class="code" prop="code">
									<el-input class="yanzhen" placeholder='验证码' v-model="ruleForm.code">
										<i slot="prefix" class="num_png"></i>
									</el-input>
									<canvas id="canvas" width="96px" height="44px" @click="handleCanvas"> </canvas>
									<a v-if="!isLogin" class="forget" @click="$router.push('/forget')">忘记密码？</a>
  								</el-form-item>
  								<el-form-item>
									<template v-if="isLogin">
										<el-button class="log" type="primary" @click="logout">退出登录</el-button>
									</template>
    								<template v-else>
										<el-button class="log" type="primary" @click="login('ruleForm')">登录</el-button>
									</template>
  								</el-form-item>
								<el-form-item>
									<el-button @click="toRegister" class="free">注册</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div class="con_two">
					<div class="notice">
						<div class="title">
							<span>学会通知</span>
							<router-link to="/notice"><img src="../assets/image/homePage/more.png" alt=""></router-link>
							<div class="title_xh">
								<img src="../assets/image/homePage/title_bot.png" alt="">
							</div>
						</div>
						<div class="notice_cont">
							<div @click="jumpNotice" class="notice_first" v-if="noticeList.length">
								<div class="notice_img">
									<img :src="noticeList[0].thumb" alt="">
								</div>
								<div class="notice_content">
									<div class="title">
										{{ noticeList[0].title }}
									</div>
									<div class="abstract">
										{{ noticeList[0].abstract }}
									</div>
									<div class="more">
										<div class="more_time">{{ noticeList[0].publish_date }}</div>
										<div>查看详情 > ></div>
									</div>
								</div>
							</div>
							<ListItem :title="'notice'" v-for="(list,index) in noticeList5" :key="list.id" :index="index" :list="list" />
						</div>
					</div>
					<div class="progress">
						<div class="title">
							<span>科技进展</span>
							<router-link to="/progress"><img src="../assets/image/homePage/more.png" alt=""></router-link>
							<div class="title_xh">
								<img src="../assets/image/homePage/title_bot.png" alt="">
							</div>
						</div>
						<div class="notice_cont">
							<div @click="jumpProgress" class="notice_first" v-if="techProList.length">
								<div class="notice_img">
									<img :src="techProList[0].thumb" alt="">
								</div>
								<div class="notice_content">
									<div class="title">
										{{ techProList[0].title }}
									</div>
									<div class="abstract">
										{{ techProList[0].abstract }}
									</div>
									<div class="more">
										<div class="more_time">{{ techProList[0].publish_date }}</div>
										<div>查看详情 > ></div>
									</div>
								</div>
							</div>
							<ListItem :title="'progress'" v-for="(list,index) in techProList5" :index="index" :key="list.id" :list="list" />
						</div>
					</div>
				</div>
				<div class="con_three">
					<div class="people">
						<div class="title">
							<span style="margin-left:-8px">学会负责人</span>
							<router-link to="/people"><img src="../assets/image/homePage/more.png" alt=""></router-link>
							<div class="title_xh">
								<img src="../assets/image/homePage/title_bot.png" alt="">
							</div>
						</div>
						<div class="peo_lubo">
							<div class="peo_img">
								<div class="peo_view">
									<div v-for="list in peoLunbo" :key="list.id">
										<router-link :to="`/people/${list.id}`"><img :src="list.avatar" alt=""></router-link>
										<div class="peo_name">{{list.name}}</div>
									</div>
								</div>
							</div>
							<div class="row_l" @click="leftRow"><img src="../assets/image/homePage/row_l.png" alt=""></div>
							<div class="row_r" @click="rightRow"><img src="../assets/image/homePage/row_r.png" alt=""></div>
						</div>

					</div>
				</div>
				<div class="con_four">
					<div class="link">
						<div class="title">
							<span>友情链接</span>
							<!-- <a href="#"><img src="../assets/image/homePage/more.png" alt=""></a> -->
							<div class="title_xh">
								<img src="../assets/image/homePage/title_bot.png" alt="">
							</div>
						</div>
						<div class="link_img">
							<!-- <a href="#"><img src="../assets/image/homePage/logo2.png" alt=""></a> -->
							<div v-for="item in logoList" :key="item.id">
								<a :href="item.redirect_url"><img :src="item.thumb" alt=""></a>
								<div></div>
							</div>
						</div>
					</div>
					<div class="contact">
						<div class="title">
							<span>联系我们</span>
							<div class="title_xh">
								<img src="../assets/image/homePage/title_white.png" alt="">
							</div>
						</div>
						<div class="con_text">
							<div>电话: <span>0311-81668672</span></div>
							<div>网站: <span>http://hebams.cn/</span></div>
							<div>邮编: <span>050018</span></div>
							<div>地址: <span>河北省石家庄市裕华区裕翔街26号</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<foot></foot>
	</div>
</template>
<script>
	import axios from '../http/axios'
	import ActionItem from '../comm/actionItem.vue' //学会动态子组件
	import ListItem from '../comm/listItem.vue' //学会通知 科技进展列表组件
	import marqueeLeft from '../comm/marqueex.vue' //消息滚动
	export default {
		inject:['reload'],
		data() {
			var checkCode = (rule, value, callback) => {
        		if (!value) {
          			return callback(new Error('验证码不能为空'));
        		}
        		setTimeout(() => {
          			if(value != this.true_code){
						return callback(new Error('验证码错误'))
					}
        		});
				callback()
      		}
			return {
				lunboActive:0,
				lunboItem:{},
				lunboTime:null,
				//搜索
				searchText:'',
				ruleForm: {
         			username: '',
          			password:'',
					code:'',
       			},
				//校验
				rules: {
          			username: [
            			{ required:true , message:'请输入邮箱', trigger: 'blur' }
          			],
          			password: [
            			{ required:true , message:'请输入密码', trigger: 'blur' }
          			],
          			code: [
            			{ validator: checkCode, trigger: 'blur' }
          			]
        		},
				//轮播图
				lunboList:[],
				// 学会动态列表
				actionList:[],
				// 学会通知列表,
				noticeList:[],
				noticeList5:[],
				// 消息轮播
				newList:[],
				// 科技进展列表
				techProList:[],
				techProList5:[],
				//学会负责人
				peoLunbo:[],
				// 友情链接
				logoList:[],
				//左右箭头移动
				peoIndex:0,
				true_code:'',
				yanzheng_arr:[]
			}
		},
		components:{
			ActionItem,
			ListItem,
			marqueeLeft
		},
		async mounted() {
			this.draw(this.yanzheng_arr)
			await this.getArticles()
		},
		computed:{
			isLogin(){
				return localStorage.getItem('uuid') ? true : false
			}
		},
		methods: {
			jumpNotice(){
				this.$router.push('/notice/' + this.noticeList[0].id)
			},
			jumpProgress(){
				this.$router.push('/progress/' + this.techProList[0].id)
			},
			// 退出登录
			logout(){
				localStorage.removeItem('uuid')
				this.reload()
			},
			// 跳转注册页面
			toRegister(){
				this.$router.push('/service?activeName=apply')
				// this.$router.push({path:'/service',query:{activeName:'apply'}})
			},
			initLunboTime(){
				if(this.lunboTime) {
					clearInterval(this.lunboTime)
				}
				this.lunboTime = setInterval(()=>{
					this.lunboActive = this.lunboActive==(this.lunboList.length-1)?0:this.lunboActive+1
					this.lunboItem = this.lunboList[this.lunboActive]
				},5000)
			},
			// 获取首页内容
			getArticles(){
				// 获取首页学会动态 学会通知 科技进展
				axios.get('articles').then(res=>{
					if(res.err_code == 200){
						this.noticeList = res.data.notice.slice(0,6)
						this.noticeList5 = this.noticeList.slice(1)
						this.newList = res.data.notice.slice(0,5)
						this.techProList = res.data.progress.slice(0,6)
						this.techProList5 = this.techProList.slice(1)
						this.actionList = res.data.dynamic.slice(0,3)
						this.lunboList = res.data.dynamic.slice(0,5)
						if(this.lunboList.length){
							this.lunboItem = this.lunboList[0]
						}
						this.initLunboTime()
					}else{
						console.log(res)
					}
				}).catch(e=>{
					console.log(e)
				})
				this.getPeople()
				this.getBiogroll()
			},
			// 获取学会负责人
			getPeople(){
				axios.get('getInsider',{params:{page:1,page_number:11}}).then(res=>{
					if(res.err_code == 200){
						this.peoLunbo = res.data.data
					}else{
						console.log(res)
					}
				}).catch(e=>{
					console.log(e);
				})
			},
			// 获取友情链接
			getBiogroll(){
				axios.get('getBlogroll').then(res=>{
					if(res.err_code == 200){
						this.logoList = res.data
					}else{
						console.log(res)
					}
				}).catch(e=>{
					console.log(e);
				})
			},
			toDetails(id){
				this.$router.push("/action/"+id)
			},
			//点击轮播
			lunBoClick(item,i){
				this.lunboItem = item
				this.lunboActive = i
				this.initLunboTime()
			},
			//点击名师在线左箭头
			leftRow(){
				// 获取移动窗口
				let peoView = document.querySelector('.con_three .peo_view')
				this.peoIndex -= 1
				if(this.peoIndex < 0){
					this.peoIndex = 0
				}
				let newPeoview = [
					{	marginLeft: -184*this.peoIndex + 'px'	}
				]
				peoView.animate(newPeoview,{
						duration:300,
						//每次动画不复位
						fill:'forwards'
				})
			},
			//点击名师在线右箭头
			rightRow(){
				//能移动的次数
				let removeCount = this.peoLunbo.length-6
				// 获取移动窗口
				let peoView = document.querySelector('.con_three .peo_view')
				this.peoIndex += 1
				if(this.peoIndex > removeCount){
					this.peoIndex = 0
				}
				let newPeoview = [
					{	marginLeft: -184*this.peoIndex + 'px'	}
				]
				peoView.animate(newPeoview,{
						duration:300,
						//每次动画不复位
						fill:'forwards'
				})
			},
			search(){ 
				if(this.searchText.trim()){
					this.$router.push('/search/'+ this.searchText)
				}
				this.searchText = ''
			},
			login(formName){
				this.$refs[formName].validate((valid) => {
          			if (valid) {
						delete this.ruleForm.code
						axios.post('auth/login',this.ruleForm).then(res=>{
							if(res.err_code==200){
								this.$message({
									message:res.msg,
									type:'success'
								})
								localStorage.setItem('uuid',res.data.uuid)
								this.reload()
							}else{
								this.$message.error(res.msg);
							}
						})
          			} else {
            			console.log('error submit!!');
            			return false;
          			}
        		});
			},
			//绘制二维码
			draw(show_num) {
      			var canvas_width =  document.querySelector("#canvas").clientWidth;
      			var canvas_height = document.querySelector("#canvas").clientHeight;
      			var canvas = document.getElementById("canvas"); //获取到canvas
      			var context = canvas.getContext("2d"); //获取到canvas画图
      			canvas.width = canvas_width;
      			canvas.height = canvas_height;
      			var sCode = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
      			var aCode = sCode.split(",");
      			var aLength = aCode.length; //获取到数组的长度

      			//4个验证码数
      			for (var i = 0; i <= 3; i++) {
        			var j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        			var deg = (Math.random() * 30 * Math.PI) / 180; //产生0~30之间的随机弧度
        			var txt = aCode[j]; //得到随机的一个内容
        			show_num[i] = txt.toLowerCase();// 依次把取得的内容放到数组里面
        			var x = 10 + i * 20; //文字在canvas上的x坐标
        			var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        			context.font = "bold 23px ''";

        			context.translate(x, y);
        			context.rotate(deg);

        			context.fillStyle = this.randomColor();
        			context.fillText(txt, 0, 0);

       				context.rotate(-deg);
        			context.translate(-x, -y);
      			}
      			//验证码上显示6条线条
      			for (var i = 0; i <= 5; i++) {
        			context.strokeStyle = this.randomColor();
        			context.beginPath();
        			context.moveTo(
          			Math.random() * canvas_width,
         			Math.random() * canvas_height
        		);
        		context.lineTo(
          			Math.random() * canvas_width,
          			Math.random() * canvas_height
        		);
        		context.stroke();
      			}
      			//验证码上显示31个小点
      			for (var i = 0; i <= 30; i++) {
        			context.strokeStyle = this.randomColor();
        			context.beginPath();
       				var x = Math.random() * canvas_width;
        			var y = Math.random() * canvas_height;
        			context.moveTo(x, y);
        			context.lineTo(x + 1, y + 1);
        			context.stroke();
      			}

      			//最后把取得的验证码数组存起来，方式不唯一
      			var num = show_num.join("");
      			// console.log(num);
      			this.true_code = num
    		},
    		//得到随机的颜色值
   		 	randomColor() {
     	 		var r = Math.floor(Math.random() * 256);
      			var g = Math.floor(Math.random() * 256);
      			var b = Math.floor(Math.random() * 256);
      			return "rgb(" + r + "," + g + "," + b + ")";
    		},
    		//canvas点击刷新
   			handleCanvas(){
      			this.draw(this.yanzheng_arr);
   			},
		}
	}
</script>
